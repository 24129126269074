$(document).on('turbolinks:load', function() {

  $('.photo-tag-form').on('ajax:success', function(event) {
    let photo_tag = event.detail[0].photo_tag
    let tagEl = tagInfoElement(photo_tag.id)
    if(tagEl) {
      tagEl.children[1].innerHTML = photo_tag.tag
      tagEl.children[3].innerHTML = photo_tag.needs_review
      tagEl.children[2].innerHTML = photo_tag.confidence
      tagEl.style.display = "";
      tagFormElement(photo_tag.id).style.display = "none";
    } else {
      console.log("reload page");
      location.reload(false);
    }
  });

  window.editPhotoTag = function (id) {
    tagInfoElement(id).style.display = "none";
    tagFormElement(id).style.display = "";
  }

  window.cancelBibUpdate = function(id) {
    tagInfoElement(id).style.display = "";
    tagFormElement(id).style.display = "none";
  }

  function tagInfoElement(id) {
    return document.getElementById(`photo-tag-${id}`);
  }

  function tagFormElement(id) {
    return document.getElementById(`photo-tag-${id}-form`);
  }

});

