// Dropzone
window.Dropzone = require("dropzone/dist/dropzone.js");
import "dropzone/dist/dropzone.css";
Dropzone.autoDiscover = false;

$(document).on('turbolinks:load', function() {
  $("#dropzone-uploader").dropzone({
      maxFilesize: 5000,
      addRemoveLinks: true,
      timeout: 300000,
      resizeWidth: 3000,
  });
});
